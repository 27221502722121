import React, { Component } from "react"
import { CookiesProvider, withCookies } from "react-cookie"
import "../assets/styles/main.scss"
import { createBrowserHistory } from "history"

import Header from "./header"
import Footer from "./footer"
import Loader from "./loader"
import SEO from "./seo"

class Layout extends Component {
  state = {
    cookieOpen: false,
    loading: true,
  }

  componentDidMount() {
    const { cookies } = this.props
    const isAcceptedCoookie = !!cookies.get("cookie-accept")
    !isAcceptedCoookie && this.setState({ cookieOpen: true })

    if (localStorage.getItem("loading") === null) {
      setTimeout(() => {
        localStorage.setItem("loading", true)
        this.setState({ loading: false })
      }, 3000)
    } else {
      this.setState({ loading: false })
    }
  }

  componentDidUpdate() {
    const history = createBrowserHistory()
    const location = history.location

    setTimeout(() => {
      window.ga("set", "page", location.pathname + location.search)
      window.ga("send", "pageview")
    }, 200)
  }

  acceptCookie = () => {
    const { cookies } = this.props

    const promiseSetCookie = new Promise(resolve =>
      resolve(cookies.set("cookie-accept", "active", { path: "/" }))
    )
    promiseSetCookie.then(() => {
      this.setState({ cookieOpen: false })
    })
  }

  render() {
    const { children, seoTitle, isHome, isThankYouPage } = this.props
    return (
      <CookiesProvider>
        <SEO title={seoTitle} />
        <Header isHome={isHome} />
        <main className="main">{children}</main>
        <Footer isHome={isHome} isThankYouPage={isThankYouPage} />
        {this.state.loading === true ? <Loader /> : ""}
        <div
          className={`cookie-baner ${
            this.state.cookieOpen ? "cookie-baner--open" : ""
          }`}
        >
          <p className="cookie-baner__text">
            Korzystamy z plików cookies w celu optymalizacji usług. Jeśli
            korzystasz z naszej witryny bez zmiany ustawień dotyczących cookies
            oznacza, że wyrażasz zgodę na zamieszczanie ich przez nas w Twoim
            urządzeniu.{" "}
            <a href="/polityka-cookies/" target="_blank">
              Czytaj więcej
            </a>
          </p>
          <button className="btn" onClick={this.acceptCookie}>
            Akceptuję
          </button>
        </div>
      </CookiesProvider>
    )
  }
}

export default withCookies(Layout)
