import React from "react"

import ContactForm from "./contactForm"

const Footer = ({ isThankYouPage }) => (
  <>
    {!isThankYouPage && <ContactForm />}
    <footer className="footer background--gray">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 footer__holder">
            <p className="footer__copy copy">Copyright © 2020 KW51</p>
          </div>
          <div className="col-sm-6">
            <div className="footer__designed">
              <p className="designed__text copy">Proudly designed by</p>
              <a href="https://adream.pl/" target="_blank" rel="noreferrer">
                <img
                  className="lazyload"
                  data-src={require("../assets/img/adream.svg")}
                  alt="strony internetowe kraków software house kraków, aplikacje webowe kraków"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
)
export default Footer
