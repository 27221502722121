import React from "react"

const Loader = () => (
    <div className="loader">
        <div className="loader__box">
            <img src={ require('../assets/img/loader.svg') } alt=""/>
        </div>
    </div>
)

export default Loader
