import React, { Component } from "react"
import { Link } from "gatsby"
import $ from "jquery"

class Header extends Component {
  state = {
    isMenuOpen: false,
    activeClass: "header--position-top",
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        let activeClass = "header--shrink"
        if (window.scrollY === 0) {
          activeClass = "header--position-top"
        }
        this.setState({ activeClass })
      })
    }

    if (this.state.isMenuOpen) {
      $("body").addClass("overflow-hidden")
    } else {
      $("body").removeClass("overflow-hidden")
    }
  }

  handleCloseMenu() {
    const state = this.state

    if (!state.isMenuOpen) {
      $("body").addClass("overflow-hidden")
    } else {
      $("body").removeClass("overflow-hidden")
    }

    this.setState(() => {
      return { isMenuOpen: !state.isMenuOpen }
    })
  }

  resetMenuState() {
    $("body").removeClass("overflow-hidden")

    this.setState(() => {
      return { isMenuOpen: false }
    })
  }

  render() {
    return (
      <header className={`header ${this.state.activeClass}`}>
        <div className="container header__container">
          <div className="row">
            <div className="col-12 header__holder">
              <Link className="header__logo-holder" to="/">
                <img
                  className="header__logo"
                  src={require("../assets/img/logo.svg")}
                  alt="Logo"
                />
              </Link>
              <div
                className={`header__menu ${
                  this.state.isMenuOpen ? " header__menu--open" : ""
                } `}
              >
                <Link
                  to="/o-inwestycji/"
                  activeClassName="menu__item--active"
                  className="menu__item"
                  // onClick={ this.setState(() => {
                  // 	return { isMenuOpen: false };
                  //   }) }
                >
                  O inwestycji
                </Link>
                <Link
                  to="/o-deweloperze/"
                  activeClassName="menu__item--active"
                  className="menu__item"
                  // onClick={ this.setState(() => {
                  // 	return { isMenuOpen: false };
                  //   }) }
                >
                  O deweloperze
                </Link>
                <Link
                  to="/dla-inwestora/"
                  activeClassName="menu__item--active"
                  className="menu__item"
                  // onClick={ this.setState(() => {
                  // 	return { isMenuOpen: false };
                  //   }) }
                >
                  Dla inwestora
                </Link>
                <Link
                  to="/lokalizacja/"
                  activeClassName="menu__item--active"
                  className="menu__item"
                  // onClick={ this.setState(() => {
                  // 	return { isMenuOpen: false };
                  //   }) }
                >
                  Lokalizacja
                </Link>
                <Link
                  to="/mieszkania/"
                  activeClassName="menu__item--active"
                  className="menu__item"
                  // onClick={ this.setState(() => {
                  // 	return { isMenuOpen: false };
                  //   }) }
                >
                  Mieszkania
                </Link>
                <Link
                  to="/dziennik-budowy/"
                  activeClassName="menu__item--active"
                  className="menu__item"
                  // onClick={ this.setState(() => {
                  // 	return { isMenuOpen: false };
                  //   }) }
                >
                  Dziennik budowy
                </Link>
                <Link
                  to="/strefa-klienta/"
                  activeClassName="menu__item--active"
                  className="menu__item"
                  // onClick={ this.setState(() => {
                  // 	return { isMenuOpen: false };
                  //   }) }
                >
                  Strefa klienta
                </Link>
                <Link
                  to="/kontakt/"
                  activeClassName="menu__item--active"
                  className="menu__item"
                  // onClick={ this.setState(() => {
                  // 	return { isMenuOpen: false };
                  //   }) }
                >
                  Kontakt
                </Link>
              </div>
              <div className="header__right-holder">
                <a href="tel:730637064" className="btn-phone">
                  <img
                    src={require("../assets/img/phone.svg")}
                    alt="Telefon"
                    className="btn-phone__icon"
                  />
                  730 637 064
                </a>
                <button
                  className={`header__burger  ${
                    this.state.isMenuOpen ? " header__burger--open" : ""
                  } `}
                  onClick={() => this.handleCloseMenu()}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
