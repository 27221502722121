import React, { Component } from "react"
import axios from "axios"
import $ from "jquery"
import { Fade } from "react-awesome-reveal"

class ContactForm extends Component {
  componentDidMount() {
    $(".input-wrapper input, .input-wrapper textarea").focusin(function () {
      $(this).parent().addClass("input-wrapper--fill")
    })
    $(".input-wrapper input, .input-wrapper textarea").focusout(function () {
      const inputVal = $(this).val().length

      if (inputVal > 0) {
        $(this).parent().addClass("input-wrapper--fill")
      } else {
        $(this).parent().removeClass("input-wrapper--fill")
      }
    })

    $(".submit-holder__overlay").on("click", () => {
      this.setState({ error: "Proszę zaznaczyć zgodę" })

      $(".check-group--accept").addClass("check-group--error")
    })
    $(".check-group--accept").on("change", () => {
      if (this.state.checked) {
        $(".check-group--accept").removeClass("check-group--error")
        $(".submit-holder__overlay").removeClass(
          "submit-holder__overlay--active"
        )
        this.setState({ error: "" })
      } else {
        $(".submit-holder__overlay").addClass("submit-holder__overlay--active")
      }
    })
  }

  state = {
    name: "",
    phone: "",
    email: "",
    message: "",
    newsletter: false,
    checked: false,
    sent: false,
    buttonText: "Wyślij",
    error: "",
  }

  submitClick = e => {
    e.preventDefault()
  }

  resetForm = () => {
    this.setState({
      name: "",
      phone: "",
      email: "",
      message: "",
      newsletter: false,
      checked: false,
      buttonText: "Wiadomość wysłana",
      error: "",
    })
  }

  formSubmit = e => {
    e.preventDefault()

    const isBrowser = typeof window !== "undefined"
    const location = isBrowser ? window.location.href : null

    this.setState({
      buttonText: "...wysyłanie",
    })

    let formData = new FormData()

    formData.set("fullName", this.state.name)
    formData.set("phone", this.state.phone)
    formData.set("mail", this.state.email)
    formData.set("message", this.state.message)
    formData.set("newsletter", this.state.newsletter)
    formData.set("pageurl", location)

    axios({
      method: "post",
      url:
        "https://kw51.zenx.pl/wp-json/contact-form-7/v1/contact-forms/5/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        this.setState({ sent: true }, this.resetForm())
        $(".input-wrapper").removeClass("input-wrapper--fill")

        if (window.location.pathname === "/dla-inwestora/") {
          window.location = "/dziekujemy-dla-inwestora/"
        } else {
          window.location = "/dziekujemy/"
        }
      })
      .catch(() => {
        console.log("Błąd wysyłania wiadomości...")
      })
  }

  render() {
    return (
      <section className="contact-form">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 col-xl-3 offset-lg-1 contact-form__content">
              <Fade delay={150} direction={"left"} triggerOnce>
                <h2 className="mb-50 content__title">
                  Biuro <br />
                  sprzedaży
                </h2>
              </Fade>
              <div className="content__box">
                <Fade delay={300} direction={"left"} triggerOnce>
                  <div className="content__info div">
                    <p className="mb-20 info__item">
                      Kazimierza Wielkiego 51
                      <br />
                      30-074 Kraków
                    </p>
                    <div className="content__link-holder info__item info__item-links div">
                      <a href="tel:+48730637064" className="content__link">
                        +48 730 637 064
                      </a>
                      <a
                        href="mailto:kontakt@kw51.pl"
                        className="content__link mb-20"
                      >
                        kontakt@kw51.pl
                      </a>
                    </div>
                    <p className="content__opening-hours mb-50 info__item">
                      <span className="bold">Godziny pracy:</span>
                      <br />
                      poniedziałek-piątek: 9.00-18.00
                      <br />
                      sobota: 10.00-15.00
                    </p>
                  </div>
                  <div className="content__info-second div">
                    <img
                      className="info-second__image lazyload"
                      data-src={require("../assets/img/klaudia.png")}
                      alt=""
                    />
                    <div className="info-second__content div">
                      <p className="bold content__item">Klaudia Jodłowska</p>
                      <p className="mb-30 content__item">Office Manager</p>
                      <div className="content__link-holder content__item-links div">
                        <a href="tel:+48730637064" className="content__link">
                          +48 730 637 064
                        </a>
                        <a
                          href="mailto:klaudia@kw51.pl"
                          className="content__link"
                        >
                          klaudia@kw51.pl
                        </a>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
            <div
              className="col-12 col-lg-5 col-lg-6 offset-lg-1 contact-form__form"
              id="form"
            >
              <Fade delay={150} direction={"right"} triggerOnce>
                <h2 className="form__title">Skontaktuj się z nami</h2>
                <p className="subtitle mb-70 form__subtitle">
                  Poznaj swój nowy dom
                </p>
              </Fade>
              <Fade delay={300} direction={"right"} triggerOnce>
                <form className="form" onSubmit={e => this.formSubmit(e)}>
                  <div className="form__row">
                    <div className="input-wrapper">
                      <label htmlFor="name">Imię i Nazwisko</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        autoComplete="off"
                        required
                        value={this.state.name}
                        onChange={e => this.setState({ name: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form__row form__row--double-items">
                    <div className="input-wrapper">
                      <label htmlFor="phone">Telefon</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        required
                        autoComplete="off"
                        value={this.state.phone}
                        onChange={e => this.setState({ phone: e.target.value })}
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="email">E-mail</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        required
                        autoComplete="off"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="input-wrapper">
                      <label htmlFor="message">Wiadomość</label>
                      <textarea
                        type="text"
                        id="message"
                        name="message"
                        required
                        autoComplete="off"
                        value={this.state.message}
                        onChange={e =>
                          this.setState({ message: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="form__row mb-20">
                    <label className="check-group check-group--accept">
                      <input
                        type="checkbox"
                        checked={this.state.checked}
                        onChange={e =>
                          this.setState({ checked: !this.state.checked })
                        }
                      />
                      <span>
                        Wyrażam dobrowolną zgodę na przetwarzanie moich danych
                        osobowych przez DES Henryk Dowgier, Anna Dowgier Spółka
                        Jawna z siedzibą w Krakowie ul. Kronikarza Galla 5/1,
                        KRS: 0000163457 (Administrator danych), w celu
                        marketingu bezpośredniego dotyczącego własnych produktów
                        i usług. Obowiązek informacyjny zgodnie z art. 13
                        ogólnego rozporządzenia o ochronie danych osobowych
                        (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z
                        04.05.2016): informacje o przetwarzaniu przez nas
                        danych, jak również o prawach osób, których dane dotyczą
                        znajdują się w naszej polityce prywatności{" "}
                        <a href="/polityka-prywatnosci/" target="_blank">
                          polityce prywatności.
                        </a>
                      </span>
                    </label>
                  </div>
                  <div className="form__row mb-40">
                    <label className="check-group">
                      <input
                        type="checkbox"
                        checked={this.state.newsletter}
                        onChange={e =>
                          this.setState({ newsletter: !this.state.newsletter })
                        }
                      />
                      <span>
                        Wyrażam zgodę na przesyłanie na podany przeze mnie adres
                        poczty elektronicznej informacji handlowych zgodnie z
                        przepisami ustawy z dnia 18 lipca 2002 r. o świadczeniu
                        usług drogą elektroniczną przyjmując jednocześnie do
                        wiadomości, że niniejsza zgoda może być odwołana w
                        każdym czasie.
                      </span>
                    </label>
                  </div>
                  <div className="form__row form__row--submit-holder">
                    <div className="submit-holder">
                      <button
                        type="submit"
                        className="btn btn--submit"
                        disabled={!this.state.checked}
                      >
                        {this.state.buttonText}
                      </button>
                      <span className="submit-holder__overlay submit-holder__overlay--active"></span>
                    </div>
                  </div>
                </form>
              </Fade>
              {this.state.error !== "" ? (
                <div className="form__error">
                  <p className="error">{this.state.error}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ContactForm
